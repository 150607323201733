import styled from "styled-components";


import { Colors, Font, breakpoints } from "@/shared/Constants";
import { onexoneWH , onexoneTitle, onexoneVolanta, onexonePicture, onexoneVideo, onexoneData, onexoneBoxTtile } from "../mixins-modules"; 


export const ContentOnexOne = styled.div`
    ${onexoneWH}
    .contentVideo{
      aspect-ratio:16/9;
    }
      .mt{
      margin-top: 10px;
      ${onexoneBoxTtile}
      ${props => props.mamsEnabled && "margin-top:35px"};
      h2.title{
      ${onexoneTitle}
       /* min-height: 90px; */
      &.shortenTitle{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        /* min-height: 75px; */
        ${breakpoints.phone}{
          min-height: auto;
          -webkit-line-clamp: inherit;
        }
      }
    }
      .volanta{
        ${onexoneVolanta}
        ${props => props.mamsEnabled && "display:none"}
      }
      .bajada{
        display:none;
      }
      .data-txt{
        ${onexoneData}
      }
    }
    picture{
      ${onexonePicture}
    }
    .contentVideo{
     height:148px;
     ${breakpoints.phone}{
      height:auto;
     }
    }
`;
