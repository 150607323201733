import { ContentOnexOne } from "./styles";
import ContentImage from "@/shared/ContentImage";
import ContentTitle from "@/shared/ContentTitle";
import { NormalArticle } from "@/widgets/Nota/Templates/stylesModulesGenericNormal.js";
import { LinkAbsolute } from "@/shared/LinkAbsolute";
import VideoLoop from "@/shared/VideoLoop";
import { clickContent } from "@/shared/Analitics/GoogleAnalytics4";
import MAM from "@/shared/MAM";
import useNewsDataModules from "@/hooks/useNewsDataModules";
import { getHourMinutes, getImageByClippings, getMonthDayYear } from "helpers";
import JWPlayer from "@/shared/JWPlayer";

const OnexOne = (props) => {

  const clippings = {
    desktop: ["rectangle_sm","16_9_medianas","2_1_S2"],
    mobile: ["rectangle_lg","rectangle_sm","16_9_medianas","2_1_S2"],
  }

  if(props?.selectedClippingId){
    clippings.desktop = [props.selectedClippingId, ...clippings.desktop];
    clippings.mobile = [props.selectedClippingId, ...clippings.mobile];
  }

  const {
    asPath,
    author = [],
    authorSignature,
    children,
    containerName,
    cutTitle = false,
    iconMultimedia,
    image,
    imagePreview,
    imageWeb,
    isPreview,
    mams,
    payWallExclusive,
    publishedDate,
    section = [],
    showDate = false,
    subtitle,
    title,
    url,
    urlTarget,
    videosOpenLoop,
    videoLoop
  } = useNewsDataModules(props, clippings);
  let date = true;
  if(publishedDate){
    const publicationDate = new Date(publishedDate);
    const currentDate = new Date();
    if (getMonthDayYear(publicationDate) != getMonthDayYear(currentDate)) {
      date = getMonthDayYear(publishedDate)
    } else {
      date = getHourMinutes(publishedDate);
    }
  }

  return (
    <>
      <NormalArticle>
        <ContentOnexOne className="onexone"  mamsEnabled={mams?.enabled}>
          {children}

          <ContentTitle
            type="onexone"
            section={section}
            author={author}
            authorSignature={authorSignature}
            title={title}
            subtitle={subtitle}
            payWallExclusive={payWallExclusive}
            date={date}
            dateHours={true}
            cutTitle={cutTitle}
            showDate={showDate}
          />
          {videosOpenLoop?.enabled ?
            <VideoLoop videoLoop={videoLoop} />
            : <picture>
                {/* <MAM sport={"futbol"} match={2368701} type="home" isPreview={isPreview}/>  */}
                {mams?.enabled && <MAM sport={mams.deporte} match={mams.idPartido} type="home" isPreview={isPreview} />}
              {isPreview ? (
                imagePreview && <ContentImage iconMultimedia={iconMultimedia} data={imagePreview} alt={title} />
              ) : (
                <ContentImage iconMultimedia={iconMultimedia} data={imageWeb} alt={title} image={image} />
              )}
            </picture>}
          <LinkAbsolute href={url} target={urlTarget} aria-label={title} onClick={() => clickContent({contentType: title, contentList: containerName},url,asPath)}/>
        </ContentOnexOne>
      </NormalArticle>
    </>
  );
};

export default OnexOne;
